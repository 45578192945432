:root {
  --primary-color: #ff0977;
  --secondary-color: #00bfa5;
  --tertiary-color: #004d99;
  --light-bg-color: #f5f5f5;
  --light-main-bg-color: #ffffff;
  --light-border-color: #ddd;
  --light-hover-bg-color: #eee;
  --light-hover-color: #000;
  --light-text-color: #333;
  --light-subtitle-color: #888;
  --light-light-text-color: #555;
}

@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-enter {
  animation: fadeIn 0.5s forwards;
}

.fade-exit {
  animation: fadeOut 0.5s forwards;
}

body {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.nav-link,
.navbar-brand {
  color: black !important;
}

.header {
  background-color: var(--primary-color) !important;
  color: black !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 50;
}

.button {
  color: black !important;
}

.footer {
  background: linear-gradient(
    135deg,
    var(--primary-color),
    var(--secondary-color),
    var(--tertiary-color)
  );
  background-size: 200% 200%;
  color: white;
  animation: gradientMove 10s ease infinite;
  border-top: 3px solid #f6f5f5;
  bottom: 0;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header .navbar-brand,
.header .nav-link,
.footer h5,
.footer p,
.footer a {
  color: white;
  transition: color 0.3s;
}

.custom-button {
  color: #f6f5f5 !important;
  border-color: #f6f5f5 !important;
}

.custom-button:hover {
  color: var(--primary-color) !important;
  background-color: #f6f5f5 !important;
  border-color: var(--primary-color) !important;
}

.header .nav-link:hover,
.btn-outline-light:hover {
  color: var(--light-text-color);
}

.main-content {
  padding-top: 80px;
  flex: 1;
  overflow-y: auto;
  padding: 20px 20px 20px 20px;
  /* background-image: url("assets/idkrx_bg.png");  */
  background-image: url("assets/idkrx_bg.avif"); /* First try AVIF */
  background-image: image-set(
    url("assets/idkrx_bg.avif") type("image/avif"),
    url("assets/idkrx_bg.webp") type("image/webp"),
    url("assets/idkrx_bg.png") type("image/png")
  );
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.content {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: var(--light-main-bg-color);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.graphics-placeholder {
  height: 300px;
  background: linear-gradient(
    135deg,
    var(--tertiary-color),
    var(--secondary-color)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-radius: 8px;
  animation: fadeIn 1s ease-in-out 0.5s;
  color: white;
}

.graphics-placeholder::before {
  content: "Graphics showcasing the app";
  color: white;
  font-size: 24px;
}

.btn-outline-light {
  border-radius: 20px;
  transition: border-radius 0.3s;
}

.btn-outline-light:hover {
  border-radius: 30px;
  background-color: var(--light-hover-bg-color);
  color: var(--light-hover-color);
  transition: background-color 0.5s ease-out, transform 0.5s ease-out;
}

.navbar-toggler {
  border-color: black;
  margin-right: 10px;
}

.navbar-nav .custom-button {
  margin-top: 10px;
  margin-left: 15px;
  background-color: #f6f5f5;
}

.navbar-nav .nav-link {
  margin-left: 15px;
}

.navbar-toggler-icon {
  filter: invert(1);
}

.scroll-container {
  min-height: 80vh;
}

.scroll-content {
  opacity: 0;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  will-change: opacity, transform;
  height: 100%;
  padding-bottom: 30vh;
}

.scroll-content.left {
  transform: translateX(-100%);
}

.scroll-content.right {
  transform: translateX(100%);
}

.scroll-content.appear.left {
  opacity: 1;
  transform: translateX(10%);
}

.scroll-content.appear.right {
  opacity: 1;
  transform: translateX(-10%);
}

.scroll-content.disappear.left {
  opacity: 0;
  transform: translateX(-100%);
}

.scroll-content.disappear.right {
  opacity: 0;
  transform: translateX(100%);
}

.graphic {
  aspect-ratio: 1/1 !important;
  width: 100% !important;
  max-width: 600px !important; /* Maximum width to constrain the video size */
  height: auto !important;
  border-radius: 50px !important;
  object-fit: cover !important; /* Ensures the video covers the area with the right aspect ratio */
}

.subtitle {
  padding-bottom: 100px;
  animation: fadeInUp 2s ease-in-out;
}

@media (max-width: 992px) {
  .navbar-nav .custom-button {
    margin-left: 10px;
    max-width: 20%;
  }

  .navbar-collapse .me-auto {
    display: flex;
    flex-direction: column;
  }

  .navbar-collapse .ms-auto {
    order: -1;
  }
}

.mailto-link {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  padding: 12px 16px; /* Increase padding for a larger touch target */
  margin: 8px; /* Add spacing between elements */
}

.mailto-link:hover {
  text-decoration: none;
}

.no-dec {
  text-decoration: none;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

.home-content {
  text-align: center;
  animation: fadeIn 1s ease-in-out;
  min-height: 200vh;
}

.header-text {
  color: #555;
  margin-top: 100px;
  z-index: 1000;
  animation: bounceIn 1.5s ease-in-out;
}

h1 {
  font-size: 3em;
  margin-bottom: 0.5em;
  color: #333;
  /* animation: bounceIn 1.5s ease-in-out; */
}

p {
  font-size: 1.2em;
  color: #666;
}

.custom-brand {
  cursor: pointer;
  background-color: #f6f5f5;
  border-radius: 15px;
}

@media (max-width: 800px) {
  .home-content h1 {
    margin-top: 150px;
  }

  .home-content p {
    margin-top: 30px;
  }
}

.low-text {
  color: black;
  font-size: 40px;
  padding: 10px;
  margin-top: 0;
  border-end-start-radius: 20px;
  border-end-end-radius: 20px;
  border-start-start-radius: -20px;
}

.text-center {
  width: 80%;
}
