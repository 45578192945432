.scroll-container {
  width: 100%; /* Full width of the viewport */
  overflow: hidden; /* Hide overflow to ensure smooth animation */
}

.scroll-content {
  opacity: 0;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  will-change: opacity, transform;
  position: relative;
  height: 400px; /* Ensure the content has height */
}

.scroll-content.left {
  transform: translateX(-100vw); /* Start off-screen to the left */
}

.scroll-content.right {
  transform: translateX(100vw); /* Start off-screen to the right */
}

.scroll-content.appear.left {
  opacity: 1;
  transform: translateX(0); /* Enter the screen */
}

.scroll-content.appear.right {
  opacity: 1;
  transform: translateX(0); /* Enter the screen */
}

.scroll-content.disappear.left {
  opacity: 0;
  transform: translateX(-100vw); /* Exit off-screen to the left */
}

.scroll-content.disappear.right {
  opacity: 0;
  transform: translateX(100vw); /* Exit off-screen to the right */
}
